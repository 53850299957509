import { motion } from "framer-motion";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { AnimationContext } from "../App";
import useWindowDimensions from "../hooks/useWindowDimensions";

type Props = {
  className?: string;
  leftCol?: React.ReactNode;
  center?: React.ReactNode;
  rightCol?: React.ReactNode;
  shouldAnimate?: boolean;
  keepImage?: boolean;
  showMobileCenter?: boolean;
  dir?: "ltr" | "ttb";
};

const Footer = styled.div`
  height: 15vh;
  width: 300px;
  max-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
    //write a gradient here
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 30%
    );
    justify-content: space-evenly;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
  padding-bottom: 0.2rem;
  position: relative;
`;

const UnderLine = styled(motion.span)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
`;

const Root = styled.div<{ dir: "ltr" | "ttb" }>`
  width: 90%;
  margin: 0 auto;
  height: 100vh;
  min-height: 700px;

  display: flex;
  flex-direction: ${(props) => (props.dir === "ltr" ? "row" : "column")};
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FirstCol = styled.div`
  flex: 1;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  max-height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Center = styled.div``;

const LastCol = styled.div`
  flex: 1;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 200px;

  background-image: url("/belowLogo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
`;

const MobileLogoBox = styled.div`
  width: 170px;
  height: 170px;
  opacity: 0.1;
  background-image: url("/belowLogo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
`;

const SvhBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  z-index: -100;

  justify-content: center;
  align-items: center;
`;

const AnimatedLogo = () => {
  const animationContext = useContext(AnimationContext);
  if (!animationContext.shouldAnimate) {
    return <LogoBox></LogoBox>;
  }
  setTimeout(() => {
    animationContext.setShouldAnimate(false);
  }, 500);

  return (
    <motion.div
      initial={{
        opacity: 0,
        transform: "scale(0.9)",
      }}
      animate={{ opacity: 1, transform: "scale(1)" }}
      transition={{ duration: 0.4, delay: 0.1 }}
    >
      <LogoBox></LogoBox>
    </motion.div>
  );
};
const AnimatedMobileLogo = () => {
  const animationContext = useContext(AnimationContext);
  if (!animationContext.shouldAnimate) {
    return (
      <SvhBox>
        <MobileLogoBox></MobileLogoBox>
      </SvhBox>
    );
  }
  setTimeout(() => {
    animationContext.setShouldAnimate(false);
  }, 500);

  return (
    <SvhBox>
      <motion.div
        initial={{
          opacity: 0,
          transform: "scale(0.9), translate(-50%, -50%)",
        }}
        animate={{ opacity: 1, transform: "scale(1), translate(-50%, -50%)" }}
        transition={{ duration: 0.4, delay: 0.1 }}
      >
        <MobileLogoBox></MobileLogoBox>
      </motion.div>
    </SvhBox>
  );
};

const MobileRoot = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const MobileTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
`;

const MobileBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
`;

export const ContentWrapper = ({
  leftCol,
  center,
  rightCol,
  className,
  keepImage,
  showMobileCenter,
  dir = "ltr",
}: Props) => {
  const path = window.location.pathname;

  const { width } = useWindowDimensions();

  const isMobile = width < 768;

  return (
    <>
      <div className={className}>
        {!isMobile ? (
          <Root dir={dir}>
            <FirstCol>{leftCol}</FirstCol>
            <Center>
              {keepImage || !center ? (
                <StyledNavLink key="home" to="/">
                  <AnimatedLogo />
                </StyledNavLink>
              ) : null}
              {center ? center : null}
            </Center>
            <LastCol>{rightCol}</LastCol>
          </Root>
        ) : (
          <>
            <MobileRoot>
              <MobileTop>{leftCol}</MobileTop>
              {showMobileCenter &&
                (center ? (
                  center
                ) : (
                  <StyledNavLink key="home" to="/">
                    <AnimatedMobileLogo />
                  </StyledNavLink>
                ))}

              <MobileBottom>{rightCol}</MobileBottom>
            </MobileRoot>
            <AnimatedMobileLogo />
          </>
        )}
      </div>
      <Footer>
        <StyledNavLink key="music" to="/music">
          Music
          {path.includes("music") && (
            <UnderLine
              transition={{ duration: 0.2, bounce: { ease: "easeInOut" } }}
              layoutId="underline"
            />
          )}
        </StyledNavLink>
        <StyledNavLink key="about" to="/about">
          About
          {path.includes("about") && (
            <UnderLine
              transition={{ duration: 0.2, bounce: { ease: "easeInOut" } }}
              layoutId="underline"
            />
          )}
        </StyledNavLink>
        {/* <StyledNavLink key="artists" to="/artists">
          Artists
          {path.includes("artists") && (
            <UnderLine
              transition={{ duration: 0.2, bounce: { ease: "easeInOut" } }}
              layoutId="underline"
            />
          )}
        </StyledNavLink> */}
      </Footer>
    </>
  );
};
