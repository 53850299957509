import styled from "styled-components";
import { ContentWrapper } from "../Components/ContentWrapper";
import { motion } from "framer-motion";

const About = styled.div`
  flex: 1;
  margin-top: 100px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const AboutText = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const Press = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  margin-top: 70px;
  gap: 70px;

  @media (max-width: 768px) {
    text-align: left;
    flex-direction: column;
    gap: 0px;
  }
`;

const LinkBox = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.5rem;
`;

const A = styled.a`
  color: black;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 1rem;
  }
  text-decoration: none;
`;

export const AboutView = () => {
  return (
    <ContentWrapper
      dir={"ttb"}
      leftCol={
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, delay: 0.1 }}
        >
          <About>
            <AboutText>
              BELOW0 is a Helsinki Based record label and collective. Founded in
              2019, BELOW0 hosts a network of artists under the same vision of
              curating the landscape of contemporary music.
              <br />
              <br />
              We are online. With passion for finding interesting people and
              projects globally, we seek to strengthen the culture of artists
              finding each other on the worldwide web. We want to connect the
              dots that need connecting and serve as the unitors of artists as
              well as their communities and subcultures. There is strength in
              unity.
            </AboutText>
          </About>
        </motion.div>
      }
      rightCol={
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, delay: 0.1 }}
        >
          <Press>
            {/* <AboutHeader>Contact:</AboutHeader> */}
            <LinkBox style={{ fontWeight: "bold" }}>
              General inquiries:{" "}
              <A href="mailto:info@below0.online">info@below0.online</A>
            </LinkBox>
            <LinkBox>
              Bookings:{" "}
              <A href="mailto:dante@below0.online">dante@below0.online</A>
            </LinkBox>
          </Press>
        </motion.div>
      }
    ></ContentWrapper>
  );
};
