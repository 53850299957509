import {
  Route,
  Routes,
  redirect,
  redirectDocument,
  useNavigate,
} from "react-router-dom";
import { HomeView } from "./Views/HomeView";
import { createContext, useEffect, useState } from "react";
import { MusicView } from "./Views/MusicView";
import { AboutView } from "./Views/AboutView";
import { GalleryView } from "./Views/GalleryView";

const Reroute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    redirect("http://eventu.al/");
  }, []);
  // redirectDocument("http://eventu.al/");
  return <div>hiiiii</div>;
};

export const AnimationContext = createContext({
  shouldAnimate: true,
  setShouldAnimate: (shouldAnimate: boolean) => {},
});

function App() {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  return (
    <>
      <AnimationContext.Provider value={{ shouldAnimate, setShouldAnimate }}>
        <Routes>
          <Route path="/" Component={HomeView}></Route>
          <Route path="/music" Component={MusicView}></Route>
          <Route path="/about" Component={AboutView}></Route>
          <Route path="/gallery" Component={GalleryView}></Route>
          <Route
            path="/BELOW0Presents"
            Component={() => {
              window.location.href = "https://www.eventu.al/en/event/4718/finland/helsinki/below0-aaniwalli-presents-snow-strippers-us";
              return null;
            }}
          />
          <Route
            path="/BLW0PL"
            Component={() => {
              window.location.href = "https://spotify.app.link/?product=open&%24full_url=https%3A%2F%2Fopen.spotify.com%2Fplaylist%2F2TOfpresM9SS5A9AMQBi5U%3Fsi%3Db92078c631304fd8%26fbclid%3DPAZXh0bgNhZW0CMTEAAaZG7lIUEpa7D7R4cmU2FfHMTKH3xOrMKGI6KZs3TsIQ_gDomE9Z-3joAws_aem_qNEafP58C2rwFB_hj-0iEQ&%24fallback_url=https%3A%2F%2Fopen.spotify.com%2Fplaylist%2F2TOfpresM9SS5A9AMQBi5U%3Fsi%3Db92078c631304fd8%26fbclid%3DPAZXh0bgNhZW0CMTEAAaZG7lIUEpa7D7R4cmU2FfHMTKH3xOrMKGI6KZs3TsIQ_gDomE9Z-3joAws_aem_qNEafP58C2rwFB_hj-0iEQ%26nd%3D1&%24android_redirect_timeout=3000&feature=organic";
              return null;
            }}
          />
          <Route
            path="/club0vol11"
            Component={() => {
              window.location.href = "https://www.eventu.al/en/event/6305/finland/helsinki/club-zero-vol11";
              return null;
            }}
          />
          <Route
            path="/club0-slagwerk-fuchs2"
            Component={() => {
              window.location.href = "https://ra.co/events/2017992";
              return null;
            }}
          />
        </Routes>
      </AnimationContext.Provider>
    </>
  );
}

export default App;
