type Song = {
  title: string;
  duration: string;
  artists?: string[];
};

export type Catalogue = {
  name: string;
  type: "single" | "ep" | "album";
  year: number;
  artists: string[];
  releaseDate: string;
  cover: string;
  songs: Song[];
  id: string;
  producers: string[];
  songwriters: string[];
};

export const CATALOGUE: Catalogue[] = [
  {
    name: "Eurosport",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "04.12.2019",
    year: 2019,
    cover: "/eurosport.jpg",
    songs: [{ title: "Eurosport", duration: "2:14" }],
    producers: ["Elias Salmenkallio", "Elja Markkanen"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elias Salmenkallio",
      "Elja Markkanen",
    ],
    id: "1",
  },
  {
    name: "Asylum",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "28.02.2020",
    year: 2020,
    cover: "any url",
    songs: [{ title: "Asylum", duration: "3:25" }],
    producers: ["Elias Salmenkallio", "Elja Markkanen", "Petro Pyysalo"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elias Salmenkallio",
      "Elja Markkanen",
      "Petro Pyysalo",
    ],
    id: "2",
  },
  {
    name: "Vulgar Vanity",
    type: "ep",
    artists: ["Glayden"],
    releaseDate: "24.04.2020",
    year: 2020,
    cover: "/vulgarVanity.jpg",
    songs: [
      { title: "Nymphomania", duration: "3:12" },
      { title: "Opium", duration: "3:07" },
      { title: "Nylon Beat", duration: "5:30" },
    ],
    producers: ["Elias Salmenkallio", "Elja Markkanen"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elias Salmenkallio",
      "Elja Markkanen",
    ],
    id: "3",
  },
  {
    name: "Bittersweet",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "10.11.2020",
    year: 2020,
    cover: "/bittersweet.jpg",
    songs: [{ title: "Bittersweet", duration: "3:32" }],
    producers: ["Niilo Lehtonen", "Elja Markkanen"],
    songwriters: ["Matti Ronaldo Mustonen", "Niilo Lehtonen", "Elja Markkanen"],
    id: "4",
  },
  {
    name: "Sunkissed",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "04.06.2021",
    year: 2021,
    cover: "/sunkissed.jpg",
    songs: [{ title: "Sunkissed", duration: "3:26" }],
    producers: ["Elias Salmenkallio", "Elja Markkanen"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elias Salmenkallio",
      "Elja Markkanen",
    ],
    id: "5",
  },
  {
    name: "INLOVE-vol.01",
    type: "ep",
    artists: ["Glayden", "inter alia", "Niko Demus"],
    releaseDate: "05.01.2022",
    year: 2022,
    cover: "any url",
    songs: [
      { title: "LUX", duration: "4:26" },
      { title: "IRIS", duration: "3:03" },
      { title: "CCTV", duration: "3:36" },
    ],
    producers: ["Elja Markkanen", "Niilo Lehtonen"],
    songwriters: ["Matti Ronaldo Mustonen", "Elja Markkanen", "Niilo Lehtonen"],
    id: "6",
  },
  {
    name: "Linea Rossa (Still Ok)",
    type: "single",
    artists: ["Glayden", "Melo"],
    releaseDate: "25.03.2022",
    year: 2022,
    cover: "/lineaRossa.jpg",
    songs: [{ title: "Linea Rossa (Still Ok)", duration: "2:48" }],
    producers: ["Elja Markkanen", "Elias Salmenkallio"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elja Markkanen",
      "Elias Salmenkallio",
      "Niko Katavainen",
    ],
    id: "7",
  },
  {
    name: "Blink",
    type: "single",
    artists: ["inter alia"],
    releaseDate: "06.05.2022",
    year: 2022,
    cover: "any url",
    songs: [{ title: "Blink", duration: "3:06" }],
    producers: ["Elja Markkanen", ""],
    songwriters: ["Elja Markkanen", "Matti Ronaldo Mustonen"],
    id: "8",
  },
  {
    name: "Exhale",
    type: "single",
    artists: ["Niko Demus"],
    releaseDate: "20.05.2022",
    year: 2022,
    cover: "any url",
    songs: [{ title: "Exhale", duration: "3:19" }],
    producers: ["Niilo Lehtonen"],
    songwriters: ["Niilo Lehtonen"],
    id: "9",
  },
  {
    name: "What U Want",
    type: "single",
    artists: ["inter alia"],
    releaseDate: "22.07.2022",
    year: 2022,
    cover: "any url",
    songs: [
      { title: "Fever Dream", duration: "1:28" },
      { title: "What u Want", duration: "4:09" },
    ],
    producers: ["Elja Markkanen"],
    songwriters: ["Elja Markkanen"],
    id: "10",
  },
  {
    name: "No Pressure",
    type: "single",
    artists: ["Glayden", "Miss Madeline"],
    releaseDate: "29.07.2022",
    year: 2022,
    cover: "/noPressure.jpg",
    songs: [{ title: "No Pressure", duration: "2:16" }],
    producers: ["Elias Salmenkallio"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Madeline Novak",
      "Elias Salmenkallio",
    ],
    id: "11",
  },
  {
    name: "Graveyard Girl",
    type: "single",
    artists: ["Niko Demus", "inter alia"],
    releaseDate: "16.09.2022",
    year: 2022,
    cover: "/graveyardGirl.jpg",
    songs: [{ title: "Graveyard Girl", duration: "3:27" }],
    producers: ["Elja Markkanen", "Niilo Lehtonen"],
    songwriters: ["Elja Markkanen", "Niilo Lehtonen"],
    id: "12",
  },
  {
    name: "T&F",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "14.10.2022",
    year: 2022,
    cover: "/t&f.jpg",
    songs: [{ title: "T&F", duration: "3:29" }],
    producers: ["Niilo Lehtonen"],
    songwriters: ["Matti Ronaldo Mustonen", "Niilo Lehtonen"],
    id: "13",
  },
  {
    name: "Scary Monsters and Dirty Sprite",
    type: "single",
    artists: ["Glayden", "CLIP"],
    releaseDate: "09.12.2022",
    year: 2022,
    cover: "/smds.jpg",
    songs: [{ title: "Scary Monsters and Dirty Sprite", duration: "2:27" }],
    producers: ["Elias Salmenkallio", "Elja Markkanen"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Shania McBean",
      "Elias Salmenkallio",
      "Elja Markkanen",
    ],
    id: "14",
  },
  {
    name: "Velvet",
    type: "single",
    artists: ["DJ JVS"],
    releaseDate: "27.01.2023",
    year: 2023,
    cover: "any url",
    songs: [{ title: "Velvet", duration: "7:20" }],
    producers: ["Joel Seppälä"],
    songwriters: ["Joel Seppälä"],
    id: "15",
  },
  {
    name: "Windblown",
    type: "ep",
    artists: ["inter alia"],
    releaseDate: "24.03.2023",
    year: 2023,
    cover: "/windblown.jpg",
    songs: [
      { title: "Call My Name", duration: "3:32" },
      { title: "CTS", duration: "3:54", artists: ["inter alia", "Niko Demus"] },
      { title: "Blue Blood", duration: "3:37" },
      { title: "Blossom", duration: "1:26" },
      { title: "In My Head", duration: "4:10" },
      { title: "Until We Die", duration: "3:44" },
    ],
    producers: ["Elja Markkanen", "Niilo Lehtonen"],
    songwriters: ["Elja Markkanen", "Niilo Lehtonen"],
    id: "16",
  },
  {
    name: "Ecuador",
    type: "single",
    artists: ["Glayden"],
    releaseDate: "10.03.2023",
    year: 2023,
    cover: "/ecuador.jpg",
    songs: [{ title: "Ecuador", duration: "1:46" }],
    producers: ["Elias Salmenkallio"],
    songwriters: ["Matti Ronaldo Mustonen", "Elias Salmenkallio"],
    id: "17",
  },
  {
    name: "Victory Lap",
    type: "ep",
    artists: ["Niko Demus"],
    releaseDate: "21.04.2023",
    year: 2023,
    cover: "/victoryLap.jpg",
    songs: [
      { title: "Circle", duration: "4:00" },
      { title: "Quicksilver", duration: "2:34" },
      {
        title: "Guided by the Light",
        duration: "2:54",
        artists: ["Niko Demus", "Glayden"],
      },
    ],
    producers: ["Niilo Lehtonen"],
    songwriters: ["Niilo Lehtonen", "Matti Ronaldo Mustonen"],
    id: "18",
  },

  {
    name: "Belief Systems",
    type: "ep",
    artists: ["Glayden"],
    releaseDate: "12.05.2023",
    year: 2023,
    cover: "/beliefSystems.jpg",
    songs: [
      {
        title: "Rack City II",
        duration: "4:19",
        artists: ["Glayden", "DJ JVS"],
      },
      {
        title: "Lifestyle",
        duration: "4:59",
        artists: ["Glayden", "Niko Demus"],
      },
      {
        title: "Best Believe",
        duration: "4:51",
        artists: ["Glayden", "inter alia"],
      },
      { title: "Again", duration: "5:47", artists: ["Glayden", "inter alia"] },
    ],
    producers: ["Joel Seppälä"],
    songwriters: ["Matti Ronaldo Mustonen", "Joel Seppälä"],
    id: "19",
  },
  {
    name: "GoGetter",
    type: "ep",
    artists: ["Glayden"],
    releaseDate: "28.07.2023",
    year: 2023,
    cover: "/goGetter.jpg",
    songs: [
      { title: "True", duration: "1:55" },
      { title: "Righteous", duration: "2:21" },
      { title: "1-2-3", duration: "1:39" },
      { title: "Cold Blood", duration: "2:59" },
      { title: "Eye2Eye", duration: "2:17" },
    ],
    producers: ["Niilo Lehtonen", "Elias Salmenkallio"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Niilo Lehtonen",
      "Elias Salmenkallio",
    ],
    id: "20",
  },
  {
    name: "Morning Star",
    type: "album",
    artists: ["Shepherd's Call"],
    releaseDate: "26.01.2024",
    year: 2024,
    cover: "/morningStar.jpg",
    songs: [
      { title: "Skin & Bones", duration: "4:24" },
      { title: "Decay", duration: "2:43" },
      { title: "Morning Star", duration: "3:19" },
      { title: "Feathering", duration: "3:38" },
      { title: "Digital Ally", duration: "2:29" },
      { title: "Sky Dome", duration: "4:39" },
      { title: "You Bleed Amber", duration: "5:00" },
      { title: "Heaven", duration: "3:50" },
    ],
    producers: ["Elja Markkanen", "Niilo Lehtonen"],
    songwriters: ["Elja Markkanen", "Niilo Lehtonen"],
    id: "21",
  },
  {
    name: "From HEL With Love",
    type: "ep",
    artists: ["VAIN", "Glayden", "inter alia", "Niko Demus", "MFM"],
    releaseDate: "02.02.2024",
    year: 2024,
    cover: "/fromHelWithLove.jpg",
    songs: [
      { title: "Innocent", duration: "2:43" },
      { title: "All 4 One", duration: "3:47" },
      { title: "1 for 1", duration: "3:19" },
      { title: "Phantom Phishing", duration: "4:40" },
    ],
    producers: ["Elja Markkanen", "Niilo Lehtonen", "Elias Salmenkallio"],
    songwriters: [
      "Matti Ronaldo Mustonen",
      "Elja Markkanen",
      "Niilo Lehtonen",
      "Elias Salmenkallio",
    ],
    id: "22",
  },
];
