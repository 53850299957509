import styled from "styled-components";
import { ContentWrapper } from "../Components/ContentWrapper";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { CATALOGUE, Catalogue } from "../Constants/CATALOGUE";
import { AnimationContext } from "../App";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Modal from "react-modal";

const groupCatalogueByYear = (catalogue: Catalogue[]) => {
  const catalogueByYear: { [key: string]: Catalogue[] } = {};
  catalogue.forEach((release) => {
    if (!catalogueByYear[release.year]) {
      catalogueByYear[release.year] = [];
    }
    catalogueByYear[release.year].unshift(release);
  });
  return catalogueByYear;
};

const LogoBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 300px;
  height: 600px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const YearBlock = styled.div`
`;

const ReleaseNavigation = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 2rem 0 4rem;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 768px) {
    display: block;
    padding-top: 0;
    position: relative;
    overflow: visible;
  }
`;

const Year = styled.div`
  padding: 1rem 0;

  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  font-size: 1.3rem;
  font-weight: 700;
  `;

const YearText = styled.div`
  padding: 10px 0;
  
  &:first-child {
    padding-top: 0;
  }

`;

const ReleaseTitle = styled.span`
  font-size: 0.8rem;
  /* font-weight: 600; */
  font-weight: 400;
  margin: 0.5rem 0;
`;

const ReleaseArtist = styled.span`
  font-size: small;
  font-weight: 300;
  font-size: 0.6rem;
  color: gray;
`;

const Cover = styled.div<{ src: string }>`
  width: 300px;
  height: 300px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px 0px rgba(174, 172, 172, 0.12);
`;

const ReleaseBlock = styled.div<{ activeRelease: boolean }>`
  /* justify-content: center; */
  align-items: center;

  padding: 10px;
  transform: translateX(-10px);
  margin: 6px 0;
  border-radius: 5px;
  line-height: 0.8rem;
  background-color: ${(props) =>
    props.activeRelease ? "#f1f0f0" : "transparent"};
  cursor: pointer;
  &:hover {
    background-color: #f1f0f0;
  }
`;

const ActiveTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: 400;
  height: 1.1rem;
  text-align: center;
`;

const ActiveInfo = styled.span`
  font-size: 0.6rem;
  color: gray;
`;
const ActiveInfoTitle = styled.span`
  margin-top: 8px;
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  display: inline-block;
`;

const StyledModal = styled(Modal)`
  height: 100vh !important;
  width: 100vw;
  overflow: scroll;
  outline: none;
`;

const MobileModalRoot = styled(motion.div)`
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const MobileModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
`;

const MobileActiveTitle = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem 0;
`;

const MiddleDot = styled.span`
  position: absolute;
  font-size: 1rem;
  top: 0;
  left: 0;
  transform: translate(85%, -50%);
`;
const MiddleDotContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 5px;
`;

const MobileActiveInfo = styled(ActiveInfo)`
  width: 300px;
  margin: 1rem auto;
`;

const CloseButton = styled.div`
  position: absolute;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`;

export const MusicView = () => {
  const [activeRelease, setActiveRelease] = useState<string | null>(null);
  const animationContext = useContext(AnimationContext);

  const CatalogueByYear = groupCatalogueByYear(CATALOGUE);

  const { width } = useWindowDimensions();

  const closeModal = () => {
    setActiveRelease(null);
  };

  const isMobile = width < 768;

  return (
    <>
      <ContentWrapper
        leftCol={
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, delay: 0.1 }}
          >
            <ReleaseNavigation>
              <YearBlock>
                {/* show releases group by catalogue year starting with latest year */}
                {Object.keys(CatalogueByYear)
                  .reverse()
                  .map((year) => {
                    return (
                      <Year>
                        <YearText>{year}</YearText>
                        {CatalogueByYear[year].map((release) => {
                          return (
                            <ReleaseBlock
                              onClick={() => setActiveRelease(release.id)}
                              activeRelease={activeRelease === release.id}
                            >
                              <ReleaseTitle>{release.name}</ReleaseTitle>
                              <MiddleDotContainer>
                                <MiddleDot>{" • "}</MiddleDot>
                              </MiddleDotContainer>
                              <ReleaseArtist>
                                {release.artists.map(
                                  (artist, i, artists) =>
                                    `${artist}${
                                      i === artists.length - 1 ? "" : ", "
                                    }`
                                )}
                              </ReleaseArtist>
                              {/* {release.songs.map((song) => {
                                return (
                                  <ReleaseSongs>{song.title}</ReleaseSongs>
                                );
                              })} */}
                            </ReleaseBlock>
                          );
                        })}
                      </Year>
                    );
                  })}
              </YearBlock>
            </ReleaseNavigation>
          </motion.div>
        }
        keepImage={activeRelease ? false : true}
        shouldAnimate
        center={
          !isMobile &&
          Object.keys(CatalogueByYear).map((year) => {
            return CatalogueByYear[year].map((release) => {
              if (release.id === activeRelease) {
                animationContext.setShouldAnimate(true);
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <LogoBox
                      onClick={() => {
                        setActiveRelease(null);
                      }}
                    >
                      <ActiveTitle>{release.name}</ActiveTitle>
                      <Cover src={release.cover} />
                      <ActiveInfo>
                        <div>
                          <ActiveInfoTitle>Tracks:</ActiveInfoTitle>{" "}
                          {release.songs.map((song, i) => {
                            if (i === release.songs.length - 1) {
                              return `${song.title}`;
                            }
                            return <span>{`${song.title},`} </span>;
                          })}
                        </div>
                        <div>
                          <ActiveInfoTitle>Release date:</ActiveInfoTitle>{" "}
                          {release.releaseDate}
                        </div>
                        <div>
                          <ActiveInfoTitle>Production:</ActiveInfoTitle>{" "}
                          {release.producers.join(", ")}
                        </div>
                        <div>
                          <ActiveInfoTitle>Writers:</ActiveInfoTitle>{" "}
                          {release.songwriters.join(", ")}
                        </div>
                      </ActiveInfo>
                    </LogoBox>
                  </motion.div>
                );
              }
              return null;
            });
          })
        }
      />
      {isMobile && (
        <AnimatePresence>
          {activeRelease && (
            <StyledModal
              ariaHideApp={false}
              isOpen={true}
              onRequestClose={closeModal}
              preventScroll={true}
              style={{
                overlay: {
                  backgroundColor: "transparent",
                },
              }}
            >
              {isMobile && (
                <MobileModalRoot
                  initial={{ y: "-700px" }}
                  animate={{ y: "0" }}
                  exit={{ y: "-700px" }}
                  transition={{ duration: 0.4 }}
                >
                  {Object.keys(CatalogueByYear).map((year) => {
                    return CatalogueByYear[year].map((release) => {
                      if (release.id === activeRelease) {
                        animationContext.setShouldAnimate(true);
                        return (
                          <MobileModalContent>
                            <MobileActiveTitle>
                              {release.name}
                            </MobileActiveTitle>
                            <Cover src={release.cover} />
                            <MobileActiveInfo>
                              <div>
                                <ActiveInfoTitle>Tracks:</ActiveInfoTitle>{" "}
                                {release.songs.map((song, i) => {
                                  if (i === release.songs.length - 1) {
                                    return `${song.title}`;
                                  }
                                  return (
                                    <div>
                                      {`${song.title},`}{" "}
                                      <br />{" "}
                                    </div>
                                  );
                                })}
                              </div>
                              <div>
                                <ActiveInfoTitle>Release date:</ActiveInfoTitle>{" "}
                                {release.releaseDate}
                              </div>
                              <div>
                                <ActiveInfoTitle>Production:</ActiveInfoTitle>{" "}
                                {release.producers.join(", ")}
                              </div>
                              <div>
                                <ActiveInfoTitle>Writers:</ActiveInfoTitle>{" "}
                                {release.songwriters.join(", ")}
                              </div>
                            </MobileActiveInfo>
                            <CloseButton onClick={() => setActiveRelease(null)}>
                              close
                            </CloseButton>
                          </MobileModalContent>
                        );
                      }
                      return null;
                    });
                  })}
                </MobileModalRoot>
              )}
            </StyledModal>
          )}
        </AnimatePresence>
      )}
    </>
  );
};
